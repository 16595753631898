<template>
  <div class="footer_layout">
    <div class="footer_main">
      <div class="footer_l">
        <img src="@/assets/logo.png" class="logo" />
        <div class="desc">
          <div>烟花行业网络解决方案</div>
          <div>致力于为烟花行业提供全方位 便捷高效的数字化解决方案</div>
          <div>免费使用</div>
        </div>
      </div>
      <div class="footer_r">
        <div>联系我们</div>
        <div>湖南省长沙市浏阳市浏阳大道116烟花贸易大厦902</div>
        <div>
          <img src="@/assets/phone_icon.png" class="phone_icon" />
          <span>155 8004 8888</span>
        </div>
        <img src="@/assets/mpweixin_code.png" class="mpweixin_code" />
        <div class="tips">
          <a href="http://beian.miit.gov.cn/" target="_blank">备案号：湘ICP备2024057644号-1</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
.footer_layout {
  position: relative;
  width: 100%;
  height: 15rem;
  background-color: #2A2C3E;
  color: #fff;
  z-index: 10;
  .footer_main {
    box-sizing: border-box;
    padding-top: 4.4164rem;
    height: 100%;
    max-width: 80%;
    min-width: 1200PX;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    overflow: hidden;
    .footer_l {
      display: flex;
      .logo {
        width: 4rem;
        height: 4rem;
        min-width: 4rem;
        margin-right: .95rem;
      }
      .desc {
        padding-top: 0.317rem;
        font-weight: 500;
        >div:nth-child(1) {
          font-size: 1.5rem;
          margin-bottom: 0.62rem;
        }
        >div:nth-child(2) {
          font-size: 0.75rem;
          margin-bottom: 0.95rem;
        }
        >div:nth-child(3) {
          display: inline-block;
          width: 6.11rem;
          height: 2.14rem;
          line-height: 2.14rem;
          text-align: center;
          font-size: 0.56rem;
          color: #5D5FEF;
          background-color: #fff;
          border-radius: 10PX;
        }
      }
    }
    .footer_r {
      >div:nth-child(1) {
        font-size: 0.567rem;
        font-weight: 600;
        margin-bottom: 0.62rem;
      }
      >div:nth-child(2) {
        font-size: 0.504rem;
        font-weight: 400;
        margin-bottom: 0.62rem;
      }
      >div:nth-child(3) {
        font-size: 0.504rem;
        font-weight: 400;
        margin-bottom: 0.63rem;
      }
      .phone_icon {
        width: 0.536rem;
        height: 0.536rem;
        margin-right: 0.283rem;
      }
      .mpweixin_code {
        width: 4.41rem;
        height: 4.41rem;
        margin-bottom: .2rem;
      }
      .tips {
        font-size: 0.27rem;
        >a {
          color: #fff;
          text-decoration: none;
        }
        >a:hover {
          color: #5D5FEF;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
